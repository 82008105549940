<template>
  <div>
    <div class="content" id="pdfDom">
      <div class="main">
        <div class="home_page">
          <div style="font-size: 80px; text-align: center; margin-top: 250px">
            情绪智力程度
          </div>
          <div style="font-size: 60px; text-align: center; margin-top: 30px">
            测评报告
          </div>
          <div style="font-size: 30px; text-align: center; margin-top: 150px">
            原著：[美]耶鲁大学心理系
          </div>
          <div style="
              font-size: 20px;
              text-align: center;
              margin-top: 200px;
              margin-bottom: 50px;
            ">
            报告接收人：{{ data.test_name }}
          </div>
        </div>
        <!--这里是分页	-->
        <DIV id="page1" style="page-break-after: always"></DIV>
        <!--分页结束-->
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">目录</div>
            <table width="100%" style="
                margin-top: 300px;
                margin-bottom: 280px;
                padding: 40px;
                line-height: 50px;
              ">
              <tbody>
                <tr>
                  <td class="line">
                    <div class="l b">情商是什么</div>
                    <div class="r">1</div>
                  </td>
                </tr>
                <tr>
                  <td class="line">
                    <div class="l b">EQ测试结果</div>
                    <div class="r">3</div>
                  </td>
                </tr>
                <tr>
                  <td class="line">
                    <div class="l">提高情商的八种方法</div>
                    <div class="r">3</div>
                  </td>
                </tr>

                <tr>
                  <td class="line">
                    <div class="l b">后记</div>
                    <div class="r">5</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--这里是分页	-->
            <DIV id="page1" style="page-break-after: always"></DIV>
            <!--分页结束-->
          </div>
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">情商是什么</div>
            <div class="txt">
              <br />
              <br />
              <br />
              <br />
              <div>
                情商（EQ）又称情绪智力，是近年来心理学家们提出的与智力和智商相对应的概念。它主要是指人在情绪、情感、意志、耐受挫折等方面的品质。以往认为，一个人能否在一生中取得成就，智力水平是第一重要的，即智商越高，取得成就的可能性就越大。但现在心理学家们普遍认为，情商水平的高低对一个人能否取得成功也有着重大的影响作用，有时其作用甚至要超过智力水平。那么，到底什么是情商呢？
              </div>
              <br />
              <br />
              <div>美国心理学家认为，情商包括以下几个方面的内容：</div>
              <br />
              <br />
              <div>
                一是认识自身的情绪。因为只有认识自己，才能成为自己生活的主宰。
              </div>
              <div>二是能妥善管理自己的情绪。即能调控自己；</div>
              <div>三是自我激励，它能够使人走出生命中的低潮，重新出发。</div>
              <div>
                四是认知他人的情绪。这是与他人正常交往，实现顺利沟通的基础；
              </div>
              <div>五是人际关系的管理。即领导和管理能力。</div>
              <br />
              <br />
              <div>
                情商的水平不像智力水平那样可用测验分数较准确地表示出来，它只能根据个人的综合表现进行判断。心理学家们还认为，情商水平高的人具有如下的特点：社交能力强，外向而愉快，不易陷入恐惧或伤感，对事业较投入，为人正直，富于同情心，情感生活较丰富但不逾矩，无论是独处还是与许多人在一起时都能怡然自得。专家们还认为，一个人是否具有较高的情商，和童年时期的教育培养有着密切的关系。
              </div>
            </div>
          </div>
          <!--这里是分页	-->
          <DIV id="page1" style="page-break-after: always"></DIV>
          <!--分页结束-->
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">EQ得分图</div>
            <div id="chart"></div>
            <div style="text-align: center">
              你的得分是{{ data.eq }}，人群平均分是{{ data.avgeq }}。
            </div>
            <div style="text-align: center">
              你比{{ data.bili }}的人的情商要高
            </div>
            <!--这里是分页	-->
            <DIV id="page1" style="page-break-after: always"></DIV>
            <!--分页结束-->
          </div>
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">EQ测试结果</div>
            <div class="txt">
              <strong>EQ得分区间与描述：</strong>
              <br />
              <br />
              <strong>90分以下</strong>
              <br />
              <br />

              说明你的EQ较低，你常常不能控制自己，你极易被自己的情绪所影响。很多时候，你轻易被击怒、动火、发脾气，这是非常危险的信号──你的事业可能会毁于你的暴躁。
              对于此最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗。正如富兰克林所说:”任何人生气都是有理的，但很少有令人信服的理由。”
              <br />
              <br />
              <strong>90～129分</strong>
              <br />
              <br />

              说明你的EQ一般，对于一件事，你不同时候的表现可能不一，这与你的意识有关，你比前者更具有EQ意识，但这种意识不是常常都有，因此需要你多加注重、时时提醒。
              <br />
              <br />
              <strong>130～149分</strong>
              <br /><br />

              说明你的EQ较高，你是一个快乐的人，不易恐惊担忧，对于工作你热情投入、敢于负责，你为人更是正义正直、同情关怀，这是你的长处，应该努力保持。
              <br /><br />
              <strong>150分以上</strong>
              <br /><br />

              那你就是个EQ高手，你的情绪聪明不但是你事业的助手，更是你事业有成的一个重要前提条件。
              <br /><br />
              <b style="color: #ff0000">你的得分：{{ data.sType }}<br />
                <br />

                {{ data.Desc }}</b>
            </div>
            <!--这里是分页	-->
            <DIV id="page1" style="page-break-after: always"></DIV>
            <!--分页结束-->
          </div>
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">提高情商的八种方法</div>
            <div class="txt">
              <strong>1、学会划定恰当的心理界限，这对每个人都有好处。</strong><br />
              <br />

              你也许自认为与他人界限不明是一件好事，这样一来大家能随心所欲地相处，而且相互之间也不用激烈地讨价还价。这听起来似乎有点道理，但它的不利之处在于，别人经常伤害了你的感情而你却不自知。<br />

              其实仔细观察周遭你不难发现，界限能力差的人易于患上病态恐惧症，他们不会与侵犯者对抗，而更愿意向第三者倾诉。如果我们是那个侵犯了别人心理界限的人，发现事实的真相后，我们会感觉自己是个冷血的大笨蛋。同时我们也会感到受伤害，因为我们既为自己的过错而自责，又对一个第三者卷进来对我们评头论足而感到愤慨。<br />

              界限清晰对大家都有好处。你必须明白什么是别人可以和不可以对你做的。当别人侵犯了你的心理界限，告诉他，以求得改正。如果总是划不清心理界限，那么你就需要提高自己的认知水平。<br />
              <br />

              <strong>2、找一个适合自己的方法，在感觉快要失去理智时使自己平静下来，从而使血液留在大脑里，做出理智的行动。</strong><br />
              <br />

              美国人曾开玩笑地说：当遇到事情时，理智的孩子让血液进入大脑，能聪明地思考问题；野蛮的孩子让血液进入四肢，大脑空虚，疯狂冲动。<br />

              是的，当血液充满大脑的，你头脑清醒，举止得当，反之，当血液都流向你的四肢和舌头的时候，你就会做蠢事，冲动暴躁，口不择言。<br />

              事实上，科学实验证明，当我们在压力之下变得过度紧张时，血液的确会离开大脑皮层，于是我们就会举止失常。此时，大脑中动物的本性起了主导作用，使我们像最原始的动物那样行事。要知道，在文明社会中，表现得像个原始动物会带来大麻烦。<br />

              控制情绪爆发有很多策略，其中一个方法就是注意你的心律，它是衡量情绪的精确尺子。当你的心跳快至每分钟100次以上时，整顿一下情绪至关重要。在这种速率下，身体分泌出比平时多得多的肾上腺素。我们会失去理智，变成好斗的蟋蟀。<br />

              当血液又开始涌向四肢时，你可以选用以下的方法来平静心情：<br />

              1、深呼吸，直至冷静下来。慢慢地、深深地吸气，让气充满整个肺部。把一只手放在腹部，确保你的呼吸方法正确。<br />
              <div id="page1" style="page-break-after: always"></div>

              2、自言自语。比如对自己说：“我正在冷静。”或者说：“一切都会过去的。”<br />

              3、有些人采用水疗法。洗个热水盆浴，可能会让你的怒气和焦虑随浴液的泡沫一起消失。<br />

              4、你也可以尝试美国心理学家唐纳?艾登的方法：想着不愉快的事，同时把你的指尖放在眉毛上方的额头上，大拇指按着太阳穴，深吸气。据艾登说，这样做只要几分钟，血液就会重回大脑皮层，你就能更冷静地思考了。<br />
              <br />

              <strong>3、想抱怨时，停一下先自问：“我是想继续忍受这看起来无法改变的情形呢，还是想改变它呢？”</strong><br />
              <br />

              对于没完没了的抱怨，我们称之为唠叨。抱怨会消耗用力而又不会有任何结果，对问题的毫无用处，又很少会使我们感到好受一点。<br />

              几乎所有的人都发现，如果对有同情心的第三方倾诉委屈，而他会跟着一起生气的话，我们会感觉好受一些。有人对你说：“可怜的宝贝。”这对你来说是莫大的安慰，你的压力似乎减轻了，于是你又能重新面对原有的局面了，尽管事情没有任何改变。<br />

              但是如果你不抱怨呢，你会感受到巨大的心理压力。压力有时并不是个坏东西，是的，它也许会让你感觉不舒服，但同时也是促使你进行改变的力量。一旦压力减轻，人就容易维持现状。然而，如果压力没有在抱怨中流失，它就会推积起来，到达一个极限，迫使你采取行动变现状。<br />

              因此，当你准备向一个同情你的朋友报怨的，先自问一下：我是想减轻压力保持现状呢，还是想让压力持续下去促使我改变这一切呢？如果是前者，那就通过报怨把压力赶走吧。逐个人都有发牢骚的时候，它会让我们暂时好受一些。但如果情况确实需要改变的话，下定决心切实行动起来吧！<br />
              <br />

              <strong>4、扫除一切浪费精力的事物。</strong><br />
              <br />

              什么是不利于我们提高情商的力量呢？答案就是一切浪费精力的事物。<br />

              许多人的神经系统就像父亲的手一样长了厚厚的老茧。我们已经习惯于意识不到精力的消耗。精力是微妙的，但也可以体会到明显的变化，比如听到好消息时，肾上腺素会激增，而听到坏消息时，会感到精疲力竭。我们通常不会留意精力细微地消耗，比如与一个消极的人相处、在桌上到处找一张纸等等。<br />

              你的生活中有哪些缓慢消耗精力的事情？我家的墙角堆着一小块地毯，每次看到它，我都会想可能有人会被它绊倒。这本不是什么大不了的问题，但它分散我的精力。这就是我们如何界定分散精力的事物――每次接触之后都会感到精力被分散了。有时和朋友所处也是如此――相互吸取和给予精力――但有些是精力的吸血鬼，他们只会吸取你的精力。这时有两个选择：一是正视这个问题，建立心理界限继续与他们谨慎交往；另一个是减少与这种人交往。<br />

              的确，我们需要去除缓慢地浪费精力的东西，解脱出来以集中精力提高我们的情商。<br />

              想加速――你可以选择减小阻力或增加推动力。<br />

              <br />

              试试我们提供的方法吧：<br />
              <br />

              1、经常列出消耗你精力的事情。<br />

              2、系统地分析一下名单，并分成两部分：<br />

              A、可以有所作为的。<br />

              B、不可改变的。<br />

              3、逐一解决A单中的问题。比如对我来说，把汽车钥匙挂在一个固定的钩子上，这样就不用到处找了。<br />

              4、再看一下B单中的问题，你是否有把握？有没有把其中一些移到A单加以解决的可能？<br />

              5、放弃B单中的问题。<br />
              <br />

              <strong>5、找一个生活中鲜活的榜样。</strong><br />
              <br />

              我们都曾经历过学榜样的年代，那些榜样对于我们来说高尚而又疏远。于是我们学榜样的热忱在和榜样的距离中渐渐熄灭了，因为我们知道，自己也许一生都成不了大英雄。<br />

              是的，你不能成为大英雄，但你可以成为一个快乐的常人，比如你的朋友丹宁，她精力充沛、年轻、大方、聪明、有趣。她经营妇科诊所、做公司顾问、为一家市被定期写专栏文章，有英俊的丈夫和可爱的女儿。<br />

              你身边有这样的出色人物吗，把他作为你的榜样吧！你可以想：她所能做的我也可以，但我们的风格迥异，我不可能以她的方式完成她所做的事。但我会模仿她做的一些事，以我的方式来完成。从她身上你总能看到从来没察觉到的自身潜能。<br />

              在周围的人中找出你学习的榜样吧！他们比你虽聪明、所受教育更好、层次更高、比你更有毅力。你会在追赶他们的过程中自然地提高自己的情商。<br />
              <br />

              <strong>6、为人父母。</strong><br />
              <br />

              为人父母会教会你很多东西。当孩子尖叫“为什么不给我买？我恨你！”时，你不能绝望，不能暴怒，你需要理解他并接受极怨恨的现实。要知道，这是孩子所能给予你的最好的礼物，当然这种恨不要持续下去。<br />

              养育孩子是一个双赢的结局。在养育孩子的过程中，孩子学会了如何与还不算成熟的年轻父母相处。作为父母的我们，则在抑制我们的需求来满足孩子的需求的过程中磨平了棱角。养育孩子会自动提高我们的情商，使我们成为更合格的父母。<br />

              如果你不愿意生养孩子，不妨试试为朋友看孩子，与孩子相处可以真正地提高我们的情商。<br />
              <br />

              <strong>7、从难以相处的人身上学到东西。</strong><br />
              <br />

              我们的周围有很多牢骚满腹，横行霸道、装胆作势的人，我们多么希望这些人从生活中消失，因为他们会让人生气和绝望，甚至发狂。为什么不能把这些人圈起来，买张飞机票，送到一个小岛上，在那里他们再也不会打扰到别人。可是，最好别这样，这些难以相处的人是我们提高情商的帮手。你可以从多嘴多舌的人身上学会沉默，从脾气暴躁的人身上学会忍耐，从恶人身上学到善良，而且你不用对这些老师感激涕零。<br />

              而且，你定义的“难以相处的人”，最终被证明可能只是与你不同的人，而对所谓的难以相处的人来说，你也是难以相处的人。<br />

              应付难以相处的人最有效的方式就是灵活。也就是说，发现他们的方式，在与之交往的过程中，尽量灵活到采用与之相同的方式。如果这人喜欢先闲谈再谈正事的话，你的反应应当是放松下来，聊聊家常。另一方面，如果这人直截了当，你也应当闲话少说，直奔主题。这样，在与难以相处的人打交道时会更有效率，而且会发现这些人并不那么难以相处。<br />

              应付难以相处的人的第二点就是把他们当成礼物。朱迪嫁给了一个霸道的人。婚姻生活对她来说充满坎坷，因为她没有很明确的界限。在分手多年以后，她学会了感谢他，因为他教给她建立和维持界限的重要性。现在再遇到这样的男人时，她根本不在乎。朱迪说：“当与他一起生活过以后，这些家伙你就会根本不放在眼里。”如果她当时嫁给了一个随和的人，她可能到现在还没有明确的界限，也很难对付那些难缠的家伙。不过，如果可以选择的话，或许我们永远不会选择难以相处的人。<br />
              <br />

              <strong>8、时不时尝试另一种完全不同的方式，你会拓宽视野，提高情商。</strong><br />
              <br />

              你是一个性格开朗外向的人还是性格内向、只喜欢独处或和几个密友在一起的人呢？你喜欢提前计划好每一天，以知道要干些什么事，还是毫无计划呢？人人都有自己的偏爱，如果可以选择的话，每个人都会选择自己偏爱的方式。然而，突然常规，尝试截然相反的行动会更有助于我们的成长。<br />

              如果你总是聚会中热衷于做中心人物，这次改改吧，试着让那些平日毫不起眼的人出出风头。如果你总是被动地等待别人和你搭讪，不妨主动上前向对方问个好。<br />
              <br />
            </div>
            <!--这里是分页	-->
            <DIV id="page1" style="page-break-after: always"></DIV>
            <!--分页结束-->
          </div>
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">后记</div>
            <br />
            <br />
            (1)本报告书内容是依当事人的问卷回答产生的结果。其内容的真实性无法保证。而是需要与当事人进一步的沟通。
            <br />
            <br />
            (2)本内容运用的领域是针对个人的行为、能力、价值及管理发展上，组织管理者不能视此报告
            作为唯一的评价依据，且本报告不负任何形式的法律问题。<br />
            <br />
            <br />
            <!--这里是分页	-->
            <DIV id="page1" style="page-break-after: always"></DIV>
            <!--分页结束-->
          </div>
        </div>
      </div>
    </div>
    <div class="print" @click="doPrint()">
      <el-button type="primary" icon="el-icon-download">下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      data: [],
    };
  },

  mounted() {
    this.ReportId = this.$route.query.id;
    this.$fecth
      .post("Appraisal/getResult", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/json/eq/eq_report_admin_json.asp",
      })
      .then((res) => {
        this.data = res;
        this.test();
      });
  },
  methods: {
    doPrint() {
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.getPdf("#pdfDom");
    },
    test() {
      let option = {
        tooltip: {},

        xAxis: {
          data: ["人群平均分", "测试得分"],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [this.data.avgeq, this.data.eq],
          },
        ],
      };
      let echarts = require("echarts");

      let myChart = echarts.init(document.getElementById("chart"));
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang='less'>
.print {
  position: fixed;
  bottom: 100px;
  right: 200px;
}
.content {
  background: white;
  max-width: 800px;
  margin: 0 auto;
}
.main {
  .home_page {
    background-image: linear-gradient(#6a2cf9, #0d82f6);
    color: white;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .body {
    padding: 20px;
  }
  .border {
    padding: 20px;
    border: 1px solid #5c85d5;
    position: relative;
    min-height: 918px;
    .title {
      position: absolute;
      top: -23px;
      left: 276px;
      height: 41px;
      width: 200px;
      background: #5c85d5;
      text-align: center;
      line-height: 40px;
      color: white;
    }
  }

  .line div {
    padding: 6px;
    font-weight: bold;
    background-color: #ffffff;
  }
  .l {
    float: left;
  }
  .r {
    float: right;
    margin-bottom: 0px;
  }
}
.txt {
  line-height: 30px;
}
#chart {
  width: 500px;
  height: 500px;
  margin: 150px auto 0;
}
</style>
